"use strict";

define('application/modules/vkb/vkb',['ojs/ojcore', 'knockout', 'jquery', 'ojs/ojknockout', 'ojs/ojrouter', 'ojs/ojaccordion', 'ojs/ojswitch', 'ojs/ojcheckboxset', 'ojs/ojlabel', 'ojs/ojoffcanvas', "../../../jet-composites/oms-virtual-keyboard/1.0.0/keyboard/loader"], function (oj, ko, $) {
  function vkbViewModel(params) {
    var self = this,
        selectedInput;
    ;
    self.initKeyboardLoad = ko.observable(true);
    self.showKeyboard = ko.observable(false);
    self.screenSmallMed = GLOBAL_UTILS.smScreen || GLOBAL_UTILS.mdScreen; // self.toggleBottom = (event) => {
    //     if (self.initKeyboardLoad()) {
    //         self.initKeyboardLoad(false);
    //         document.querySelector("#oms-vkb").initKeyboard();
    //     }
    //     if (self.showKeyboard()) {
    //         self.showKeyboard(false);
    //         // window.parent.localSignals.virtualKeyboardClose.dispatch();
    //     } else {
    //         self.showKeyboard(true);
    //         // window.parent.localSignals.virtualKeyboardOpen.dispatch();
    //     }
    //     if (event.type === "click") {
    //         $("#mainOuter").toggleClass("oms-keyboard-main");
    //         $(".oj-offcanvas-outer-wrapper").toggleClass("oms-keyboard-outer-wrapper");
    //     }
    //     return oj.OffcanvasUtils.toggle(self.drawerBottomParams);
    // }
    // Footer
  }

  return vkbViewModel;
});
