"use strict";

define('application/modules/footer/footer',['ojs/ojcore', 'knockout', 'jquery', 'ojs/ojknockout', 'ojs/ojrouter', 'ojs/ojaccordion', 'ojs/ojswitch', 'ojs/ojcheckboxset', 'ojs/ojlabel', 'ojs/ojoffcanvas', "../../../jet-composites/oms-virtual-keyboard/1.0.0/keyboard/loader"], function (oj, ko, $) {
  function FooterViewModel(params) {
    var self = this,
        selectedInput;
    ;
    self.initKeyboardLoad = ko.observable(true);
    self.showKeyboard = ko.observable(false);
    self.screenSmallMed = GLOBAL_UTILS.smScreen || GLOBAL_UTILS.mdScreen;
    self.isWorkStationFlowEnabled = ko.observable(false);
    GLOBAL_isPOSenabled.subscribe(function (newValue) {
      if (newValue === true) {
        self.isWorkStationFlowEnabled(true);
      } else {
        self.isWorkStationFlowEnabled(false);
      }
    }); // self.isWorkStationFlowEnabled.subscribe((newValue) => {
    //     if (newValue) {
    //         self.toggleBottom();
    //     }
    // });

    self.about = GLOBAL_UTILS.lang.get('fAbout', 'footer');
    self.legal = GLOBAL_UTILS.lang.get('fLegal', 'footer');
    self.terms = GLOBAL_UTILS.lang.get('fTerms', 'footer');
    self.privacy = GLOBAL_UTILS.lang.get('fPrivacy', 'footer');
    self.contact = GLOBAL_UTILS.lang.get('fContact', 'footer');
    self.copyright = GLOBAL_UTILS.lang.get('fCopy', 'footer', ['2002', new Date().getFullYear()]);
    self.drawerBottomParams = {
      selector: "#bottomDrawer",
      content: "#mainOuter",
      autoDismiss: "none",
      displayMode: "overlay"
    }; // self.toggleBottom = () => {
    //     if (self.initKeyboardLoad()) {
    //         self.initKeyboardLoad(false);
    //         let linkElement = document.querySelector("#oms-vkb");
    //         let busyContext = oj.Context.getContext(linkElement).getBusyContext();
    //         busyContext.whenReady().then(function () {
    //             document.querySelector("#oms-vkb").initKeyboard();
    //             document.getElementById("user-name-input").focus();
    //         });
    //     }
    //     if (self.showKeyboard()) {
    //         self.showKeyboard(false);
    //         // window.parent.localSignals.virtualKeyboardClose.dispatch();
    //     } else {
    //         self.showKeyboard(true);
    //         // window.parent.localSignals.virtualKeyboardOpen.dispatch();
    //     }
    //     // if (event.type === "click") {
    //     $("#pageContent").toggleClass("oms-keyboard-main");
    //     $(".oj-offcanvas-outer-wrapper").toggleClass("oms-keyboard-outer-wrapper");
    //     // }
    //     return oj.OffcanvasUtils.toggle(self.drawerBottomParams);
    // }
    // if (self.isWorkStationFlowEnabled()) {
    //     self.toggleBottom();
    // }
    // Footer

    function footerLink(name, id, linkTarget) {
      this.name = name;
      this.linkId = id;
      this.linkTarget = linkTarget;
    }

    self.footerLinks = ko.observableArray([new footerLink(self.about, 'aboutOracle', 'https://www.oracle.com/corporate/#menu-about'), new footerLink(self.legal, 'legalNotices', 'http://www.oracle.com/us/legal/index.html'), new footerLink(self.terms, 'termsOfUse', 'https://www.oracle.com/legal/terms.html'), new footerLink(self.privacy, 'yourPrivacyRights', 'https://www.oracle.com/us/legal/privacy/'), new footerLink(self.contact, 'contactUs', 'http://www.oracle.com/pls/topic/lookup?ctx=F12967-01&id=Browsers')]);
  }

  return FooterViewModel;
});
